<template>
    <div class="terminal_page">
        <template v-if="admin">
            <div class="grid-layout-container alignment-block">
                <vs-row>
                    <vs-col vs-w="3" vs-xs="12" vs-sm="6" class="btn_sale">
                        <BoxVente
                            title="Ventes séances"
                            :value="millier(vente_seance.toFixed(2))+' €'"
                            background="rgb(170,222,135)"
                        />
                    </vs-col>
                    <vs-col vs-w="3" vs-xs="12" vs-sm="6" class="btn_sale">
                        <BoxVente
                            title="Ventes accessoires"
                            :value="millier(vente_accesoire.toFixed(2))+' €'"
                            background="rgb(170,204,255)"
                        />
                    </vs-col>
                    <vs-col vs-w="3" vs-xs="12" vs-sm="6" class="btn_sale">
                        <BoxVente
                            title="Chiffre affaire mensuel"
                            :value="millier(vente_ca_mensuel.toFixed(2))+' €'"
                            background="rgb(147,157,172)"
                            textColor="white"
                        />
                    </vs-col>
                    <vs-col vs-w="3" vs-xs="12" vs-sm="6" class="btn_sale">
                        <BoxVente
                            title="Chiffre affaire annuel"
                            :value="millier(vente_ca_annuel.toFixed(2))+' €'"
                            background="#515A5A"
                            textColor="white"
                        />
                    </vs-col>
                </vs-row>
            </div>
            <br/>


            <div class="vx-row">
                <div class="vx-col w-full mb-base">
                    <vx-card class="filtre">
                        <vs-row>
                            <vs-col vs-w="4" vs-sm="12" vs-xs="12">
                                <div class="container">
                                    <h6>Date</h6>
                                    <cDate month="1" v-model="list_date" class="w-full" />
                                </div>
                            </vs-col>
                            <vs-col vs-w="4" vs-sm="12" vs-xs="12">
                                <div class="container">
                                    <h6>Type</h6>
                                    <vs-select v-model="list_type" class="w-full select-large" v-on:change="changeType()">
                                        <vs-select-item key="all" value="all" text="Tout" class="w-full" />
                                        <vs-select-item key="fa" value="fa" text="Facture" class="w-full" />
                                        <vs-select-item key="re" value="re" text="Reçu" class="w-full" />
                                        <vs-select-item key="av" value="av" text="Avoir" class="w-full" />
                                    </vs-select>
                                </div>
                            </vs-col>
                            <vs-col vs-w="4" vs-sm="12" vs-xs="12">
                                <div class="container">
                                    <h6>Terminal</h6>
                                    <vs-select v-model="terminal_uid" class="w-full select-large" v-on:change="changeTerminal()">
                                        <vs-select-item :key="term.value" :value="term.value" :text="term.text" v-for="term in terminal_list" class="w-full" />
                                    </vs-select>
                                </div>
                            </vs-col>
                        </vs-row>
                    </vx-card>
                    <br/>



                    <vx-card>
                        <vs-table
                            v-model="selected"
                            @selected="handleSelected"
                            pagination
                            max-items="20"
                            search
                            :data="list_facture"
                            noDataText="Aucun résultat">

                                <template slot="header">
                                    <h3>Factures</h3>
                                </template>

                                <template slot="thead">
                                    <vs-th sort-key="number">Numéro</vs-th>
                                    <vs-th sort-key="date">Date</vs-th>
                                    <vs-th sort-key="member">Membre</vs-th>
                                    <vs-th sort-key="item">Produit(s)</vs-th>
                                    <vs-th sort-key="payement">Paiement(s)</vs-th>
                                    <vs-th sort-key="total">Montant TTC</vs-th>
                                </template>

                                <template slot-scope="{data}">
                                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                                        <vs-td :data="data[indextr].number">
                                            {{ data[indextr].type }}<br/>N°{{ data[indextr].number }}
                                        </vs-td>
                                        <vs-td :data="data[indextr].date">
                                            {{ data[indextr].vdate }}
                                        </vs-td>
                                        <vs-td :data="data[indextr].member" class="nowrap">
                                            {{ data[indextr].member.first_name }}<br/>
                                            {{ data[indextr].member.last_name }}
                                        </vs-td>
                                        <vs-td :data="data[indextr].item">
                                            <span v-for="item in data[indextr].item" :key="item.uid">
                                                <b>{{ item.name }}</b>
                                                <template v-if="item.credit!=0">
                                                    <i class="blue"> ( {{ item.credit}} Crédit)</i>
                                                </template>
                                                <br/>
                                            </span>
                                        </vs-td>
                                        <vs-td :data="data[indextr].payement">
                                            <span v-for="paye in data[indextr].payement" :key="paye.message">
                                                <b>{{ paye.name }}</b>: {{ paye.value }} €<br/>
                                            </span>
                                        </vs-td>
                                        <vs-td :data="data[indextr].total">
                                            <template v-if="data[indextr].total!=0">
                                                {{ data[indextr].total }} €
                                            </template>
                                        </vs-td>
                                    </vs-tr>
                                </template>
                            </vs-table>
                    </vx-card>
                </div>
            </div>
        </template>
        <template v-else>
            <h2>Accés non autorisé.</h2>
        </template>
    </div>
</template>



<style lang="scss" scoped >
.btn_sale{
    text-align:center;
    p{
        font-size:2em;
    }
    .vx-card__title,.vx-card__header,.vx-card{
        text-align:center !important;
        margin:auto;
    }
    .vx-card{
    margin-left:10px !important;
    margin-right:10px !important;
    }
}
.nowrap{
    white-space:nowrap;
}
.blue{
    color:blue;
}

@media only screen and (max-width: 900px)
{
    .filtre .container{
        margin-bottom:20px !important;
    }
}
</style>

<script>
import Utils from '@/assets/utils/utils'
import Fdate from '@/assets/utils/fdate'


import Terminal from '@/database/models/terminal'
import Invoice from '@/database/models/invoice'
import Item from '@/database/models/item'
import Member from '@/database/models/member'

import BoxVente from '@/components/app/boxVente'
import cDate from '@/components/app/date'
import FactureView from '@/views/page/facturationViews/factures.vue'
export default {
    components: {
        BoxVente,
        cDate,
        FactureView,
    },
    data(){
        return{
            terminal_uid :'?',
            terminal_name:'?',
            terminal_list:[],

            list_facture:[],
            selected:[],

            list_type:"all",

            list_date: new Date(),


            vente_seance:0,
            vente_accesoire:0,
            vente_ca_mensuel:0,
            vente_ca_annuel:0,

            admin:false,
        }
    },
    watch:{
        list_date:function(){
            this.changeDate()
        },

    },
	mounted:function()
	{
        this.RoleAdmin();
        ListRefresh(this);
        frefresh(this);
	},
    methods:{
        millier:Utils.Millier,
        //change terminal
        changeTerminal:function()
        {
            //change terminal page
            if(this.$route.params.uid != this.terminal_uid)
            {
                this.list_facture = [];
                frefresh(this);
            }
            return;
        },
        changeDate:function(){
            frefresh(this)
        },
        changeType(){
            frefresh(this)
        },
        refresh:function(){
            ListRefresh(this);
        },
        handleSelected()
        {
            //voir facture
            this.$router.push('/admin/invoice/'+this.selected.uid);
        },


        RoleAdmin()
        {
            this.admin = false;
                  //Chargement info user
            let uid = this.$srvApi.getLoginUserUid();
            Member.getMemory(uid, (mbr)=>
            {
                this.admin = mbr.type.toLowerCase() == "a";
            });
        }
    }
};

//refresh list terminal
function ListRefresh(root)
{
    //---------------------------------
    //liste terminal
    Terminal.getAllMemory( function(fbuf)
    {
        let tab = [];
        //global terminal
        tab.push({
            value : 'all',
            text  : 'Tout',
        });

        //add terminal
        for( const i in fbuf )
        {
            //add change
            tab.push({
                value : fbuf[i].uid,
                text  : fbuf[i].mac,
            });

            //find actu
            if( root.$route.params.uid==fbuf[i].uid )
            {
                root.terminal_uid  = fbuf[i].uid;
                root.terminal_name = fbuf[i].mac;
            }
            if(root.$route.params.uid == 'all')
            {
                root.terminal_uid  = 'all';
                root.terminal_name = 'Tout';
            }
        }
        root.terminal_list = tab;
    });
}


//Facture in terminal
function frefresh(root)
{
    //---------------------------------
    //List facture
    Invoice.getAllMemory( function(fbuf)
    {
        //vider mémoire
        root.list_facture = [];

        //stats
        state(fbuf, root)

        //complete data
        function completeDate(buffer)
        {
            //filtre terminal
            if(buffer.terminal!=root.$route.params.uid )
            if(root.$route.params.uid!='all')
                return

            //filtre verif type
            if(root.list_type != "all")
            if(buffer.type != root.list_type )
                return

            //filtre par date
            if(root.list_date != null)
            {
                let tfmin = (new Date( root.list_date.getFullYear(), root.list_date.getMonth(), 1 )).getTime()
                let tfmax = (new Date( root.list_date.getFullYear(), root.list_date.getMonth()+1, 1 )).getTime()
                let facd  = (new Date(buffer.createAt)).getTime()
                if( facd < tfmin )
                    return
                if( facd > tfmax)
                    return
            }


            Member.getMemory( buffer.member, function(mbr)
            {
                //Si pas de membre ?
                let p_member = { first_name:'Aucun', last_name:'' };
                if(mbr)
                    p_member = mbr;

                //get vendor
                Member.getMemory( buffer.vendor, function(ved)
                {
                    let p_vendor = { first_name:'Aucun', last_name:'' };
                    if(ved)
                        p_vendor = ved;

                    //payement
                    let paye = []
                    if(buffer.pay_money!=0)
                        paye.push({name:'ESP', value:buffer.pay_money})

                    if(buffer.pay_cb!=0)
                        paye.push({name:'CB',value:buffer.pay_cb})

                    if(buffer.pay_check!=0)
                        paye.push({name:'CHEQ',value:buffer.pay_check})

                    if(buffer.pay_dif!=0)
                        paye.push({name:'DIFF',value:buffer.pay_dif})

                    if(buffer.pay_prele!=0)
                        paye.push({name:'PREL',value:buffer.pay_prele})

                    //Item
                    Item.getTabAllMemoryKey( buffer.uid, function(items)
                    {
                        //add
                        root.list_facture.push({
                            uid      : buffer.uid,
                            type     : Invoice.getTypeName(buffer),
                            date     : Fdate.getFullDateMin(buffer.createAt,true)+" "+Fdate.getHeure(buffer.createAt),
                            vdate    : Fdate.getFullDateMin(buffer.createAt),
                            number   : buffer.number,
                            vendor   : {'first_name':Utils.StrCapital(p_vendor.first_name), 'last_name':Utils.StrCapital(p_vendor.last_name)},
                            member   : {'first_name':Utils.StrCapital(p_member.first_name), 'last_name':Utils.StrCapital(p_member.last_name)},
                            item     : items,
                            payement : paye,
                            total    : (buffer.pay_cb + buffer.pay_money + buffer.pay_check + buffer.pay_dif + buffer.pay_prele).toFixed(2),
                        });
                    })
                });
            });
        }

        //lister facture
        for( var i in fbuf )
            completeDate(fbuf[i]);
    });
}


function state( list_fac, root ){

    let date_actu = new Date(root.list_date)

    let Amin = (new Date( date_actu.getFullYear(), 0, 1, 0, 0, 0 )).getTime()
    let Amax = (new Date( date_actu.getFullYear()+1, 0, 1, 0, 0, 0 )).getTime()

    let Mmin = (new Date( date_actu.getFullYear(), date_actu.getMonth(), 1, 0, 0, 0 )).getTime()
    let Mmax = (new Date( date_actu.getFullYear(), date_actu.getMonth()+1, 1, 0, 0, 0)).getTime()

    //reset box state
    root.vente_ca_annuel = 0
    root.vente_ca_mensuel = 0
    root.vente_seance = 0
    root.vente_accesoire = 0

    for( var i in list_fac )
    {
        //get facture
        let buffer = list_fac[i]
        let total = (buffer.pay_cb + buffer.pay_money + buffer.pay_check + buffer.pay_dif + buffer.pay_prele)

        let facd  = (new Date(buffer.createAt)).getTime()

        //Calcule CA anné
        if(facd>=Amin)
        if(facd<=Amax)
            root.vente_ca_annuel += total

        //Calcule CA mois
        if(facd>=Mmin)
        if(facd<=Mmax)
        {
            //CA mois
            root.vente_ca_mensuel += total
        }
    }

    //Item
    Item.getAllMemory( function(items)
    {
        for( var p in items)
        {
            let buffer = items[p]
            let itdate  = (new Date(buffer.createAt)).getTime()

            //Calcule CA mois
            if(itdate>=Mmin)
            if(itdate<=Mmax)
            if(buffer.prix!=0)
            if(buffer.tva!=0)
            if(buffer.type!='re')
            {
                let total = 0
                //Si Facture
                if( buffer.type =='fa' || buffer.type =='re' )
                    total = parseFloat((buffer.prix * ((buffer.tva/100)+1)).toFixed(2))

                //Sinon avoir
                if( buffer.type =='av' )
                    total = parseFloat((buffer.prix * (( (buffer.tva*-1)/100)+1)).toFixed(2))

                if( buffer.credit!=0)
                    root.vente_seance += total
                else
                    root.vente_accesoire += total

            }
        }
    })
}

</script>
