<template>
  <div>
      <vs-row v-if="admin">
          <graph ref="graphYear"></graph>
      </vs-row>
     <br>
  <vx-card>
    <vs-table
        v-model="selected"
        @selected="handleSelected"
        pagination
        max-items="10"
        search
        :data="list_facture"
        noDataText="Aucun résultat"
        >

            <template slot="header">
                <h3>Factures</h3>
            </template>

            <template slot="thead">
                <vs-th sort-key="number">Numéro</vs-th>
                <vs-th sort-key="date">Date</vs-th>
                <vs-th sort-key="member">Membre</vs-th>
                <vs-th sort-key="item">Produit(s)</vs-th>
                <vs-th sort-key="payement">Paiement(s)</vs-th>
                <vs-th sort-key="total">Montant TTC</vs-th>
            </template>

            <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].number">
                        {{ data[indextr].type }}<br/>N°{{ data[indextr].number }}
                    </vs-td>
                    <vs-td :data="data[indextr].date">
                        {{ data[indextr].vdate }}
                    </vs-td>
                    <vs-td :data="data[indextr].member" class="nowrap">
                        {{ data[indextr].member.first_name }}<br/>
                        {{ data[indextr].member.last_name }}
                    </vs-td>
                    <vs-td :data="data[indextr].item">
                        <span v-for="item in data[indextr].item" :key="item.uid">
                            <b>{{ item.name }}</b>
                            <template v-if="item.credit!=0">
                                <i class="blue"> ( {{ item.credit}} Crédit)</i>
                            </template>
                            <br/>
                        </span>
                    </vs-td>
                    <vs-td :data="data[indextr].payement">
                        <span v-for="paye in data[indextr].payement" :key="paye.message">
                            <b>{{ paye.name }}</b>: {{ paye.value }} €<br/>
                        </span>
                    </vs-td>
                    <vs-td :data="data[indextr].total">
                        <template v-if="data[indextr].total!=0">
                            {{ data[indextr].total }} €
                        </template>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
     </vx-card>
  </div>

</template>

<script>
import Utils from '@/assets/utils/utils'
import Fdate from '@/assets/utils/fdate'
import graphFacture from '@/components/stats/FacturationSeanceGraph.vue'
import accessGraph from '@/components/stats/FacturationAccessGraph.vue'
import graph from '@/components/stats/graphFacture.vue'

import Terminal from '@/database/models/terminal'
import Invoice from '@/database/models/invoice'
import Item from '@/database/models/item'
import Member from '@/database/models/member'

import BoxVente from '@/components/app/boxVente'
import cDate from '@/components/app/date'
import VxCard from '@/components/vx-card/VxCard.vue'

import autoRefresh from '@/assets/utils/autoRefresh'


export default {
    name:'factures',
    components: {
        BoxVente,
        cDate,
        VxCard,
        graphFacture,
        accessGraph,
        graph,
        autoRefresh
    },
    props:[
        'shared',
        // shared change bien, mais comment modifier celui du this !!
    ],
    data(){
        return{
            
            terminal_uid :this.shared.terminal_uid,
            terminal_name:this.shared.terminal_name,
            terminal_list:this.shared.terminal_list,

            list_facture:this.shared.list_facture,
            selected:this.shared.selected,

            list_type:this.shared.list_type,

            list_date: this.shared.list_date,


            vente_seance:this.shared.vente_seance,
            vente_accesoire:this.shared.vente_accesoire,
            vente_ca_mensuel:this.shared.vente_ca_mensuel,
            vente_ca_annuel:this.shared.vente_ca_annuel,

            admin:this.shared.admin,
        }
    },
    watch:{
        list_date:function(){
            
           // this.changeDate();
           //console.log(" date cliquer : ", this.list_date)
            // Mon Feb 01 2021 19:53:00 GMT+0100 (CET)
        },
        vente_ca_mensuel:function(){
           // console.log(" mois ca changed")
        },
        list_facture:function(){
           // console.log(" list facture changed : f  ", this.list_facture)
        },
        



    },
	mounted:function()
	{
        autoRefresh.SetCallback(()=>{
            this.RoleAdmin();
            ListRefresh(this);
            frefresh(this);
            
        });
        autoRefresh.refresh()
	},
    methods:{
        millier:Utils.Millier,
        //change terminal

        changeTerminal:function(elem)
        {
            this.terminal_uid=elem;
            //console.log(" parent termina changed ", this.terminal_uid)
            if(this.$route.params.uid != this.terminal_uid)
            {
                this.$router.push('/admin/facturesView/'+this.terminal_uid);
                this.list_facture = [];
                frefresh(this);
            }
            return;
        },

        log(){
            //console.log("this is the shared : ", this.shared, " and this data  ", this.$data);
        },
       listChange(/*event*/){
           //console.log(" recu : ", event);
       },
        changeDate:function(alldata){
           // console.log(" essai all data ", alldata.vente_ca_mensuel)
           // console.log(" parent date changed ", "actuel : ", alldata.list_date)
           var load = false; 
         let d = new Date(alldata.list_date)
         if ( this.list_date.getFullYear()!= d.getFullYear()){
                load=true;
         }
         
        //    console.log(" changement ; ", alldata)
            this.transferInfo(alldata);
           // console.log(" after : ", this.list_date);
            frefresh(this)
            if ( load == true){
                this.$refs.graphYear.frefresh( (new Date(this.list_date)).getFullYear() );
            }
           // console.log(" lst fact : ", this.list_facture)

        },
        changeType(alldata){
            //console.log(" parent type changed ")
            this.transferInfo(alldata);
            frefresh(this)
        },
        refresh:function(){
            ListRefresh(this);
        },
        handleSelected()
        {
            //voir facture
            this.$router.push('/admin/invoice/'+this.selected.uid);
        },
        transferInfo(alldata){
            this.terminal_uid =alldata.terminal_uid,
            this.terminal_name=alldata.terminal_name,
            this.terminal_list=alldata.terminal_list,

            this.list_facture=alldata.list_facture,
            this.selected=alldata.selected,

            this.list_type=alldata.list_type,

            this.list_date= alldata.list_date;


            this.vente_seance=alldata.vente_seance,
            this.vente_accesoire=alldata.vente_accesoire,
            this.vente_ca_mensuel=alldata.vente_ca_mensuel,
            this.vente_ca_annuel=alldata.vente_ca_annuel,

            this.admin=alldata.admin
        },

        RoleAdmin()
        {
            this.admin = false;
                  //Chargement info user
            let uid = this.$srvApi.getLoginUserUid();
            Member.getMemory(uid, (mbr)=>
            {
                this.admin = mbr.type.toLowerCase() == "a";
            });
        }
    }
};

//refresh list terminal
function ListRefresh(root)
{

    
    //---------------------------------
    //liste terminal
    Terminal.getAllMemory( function(fbuf)
    {
        let tab = [];
        //global terminal
        tab.push({
            value : 'all',
            text  : 'Tout',
        });

        //add terminal
        for( const i in fbuf )
        {
            //add change
            tab.push({
                value : fbuf[i].uid,
                text  : fbuf[i].mac,
            });

            //find actu
            if( root.$route.params.uid==fbuf[i].uid )
            {
                root.terminal_uid  = fbuf[i].uid;
                root.terminal_name = fbuf[i].mac;
            }
            if(root.$route.params.uid == 'all')
            {
                root.terminal_uid  = 'all';
                root.terminal_name = 'Tout';
            }
        }
        root.terminal_list = tab;
    });
}



//Facture in terminal
function frefresh(root)
{
    //let data_year = (new Date(root.list_date)).getFullYear();
    //console.log( data_year );
    //root.$refs.graphYear.frefresh( (new Date(root.list_date)).getFullYear() );

    
    //---------------------------------
    //List facture
    Invoice.getAllMemory( function(fbuf)
    {
       

        //vider mémoire
        root.list_facture = [];

        //stats
        state(fbuf, root)

        //complete data
        function completeDate(buffer)
        {
            
            //filtre terminal
            if(buffer.terminal!=root.$route.params.uid )
            if(root.$route.params.uid!='all')
                return

            //filtre verif type
            if(root.list_type != "all")
            if(buffer.type != root.list_type )
                return

            //filtre par date
            if(root.list_date != null)
            {
                let tfmin = (new Date( root.list_date.getFullYear(), root.list_date.getMonth(), 1 )).getTime()
                let tfmax = (new Date( root.list_date.getFullYear(), root.list_date.getMonth()+1, 1 )).getTime()
                let facd  = (new Date(buffer.createAt)).getTime()
                if( facd < tfmin )
                    return
                if( facd > tfmax)
                    return
            }


            Member.getMemory( buffer.member, function(mbr)
            {
                //Si pas de membre ?
                let p_member = { first_name:'Aucun', last_name:'' };
                if(mbr)
                    p_member = mbr;

                //get vendor
                Member.getMemory( buffer.vendor, function(ved)
                {
                    let p_vendor = { first_name:'Aucun', last_name:'' };
                    if(ved)
                        p_vendor = ved;

                    //payement
                    let paye = []
                    if(buffer.pay_money!=0)
                        paye.push({name:'ESP', value:buffer.pay_money})

                    if(buffer.pay_cb!=0)
                        paye.push({name:'CB',value:buffer.pay_cb})

                    if(buffer.pay_check!=0)
                        paye.push({name:'CHEQ',value:buffer.pay_check})

                    if(buffer.pay_dif!=0)
                        paye.push({name:'DIFF',value:buffer.pay_dif})

                    if(buffer.pay_prele!=0)
                        paye.push({name:'PREL',value:buffer.pay_prele})

                    //Item
                    Item.getTabAllMemoryKey( buffer.uid, function(items)
                    {

                        //add
                        root.list_facture.push({
                            uid      : buffer.uid,
                            type     : Invoice.getTypeName(buffer),
                            date     : Fdate.getFullDateMin(buffer.createAt,true)+" "+Fdate.getHeure(buffer.createAt),
                            vdate    : Fdate.getFullDateMin(buffer.createAt),
                            number   : buffer.number,
                            vendor   : {'first_name':Utils.StrCapital(p_vendor.first_name), 'last_name':Utils.StrCapital(p_vendor.last_name)},
                            member   : {'first_name':Utils.StrCapital(p_member.first_name), 'last_name':Utils.StrCapital(p_member.last_name)},
                            item     : items,
                            payement : paye,
                            total    : (buffer.pay_cb + buffer.pay_money + buffer.pay_check + buffer.pay_dif + buffer.pay_prele).toFixed(2),
                        });
                    })
                });
            });
        }

        //lister facture

        
        for( var i in fbuf )
            completeDate(fbuf[i]);

       

    });
}


function state( list_fac, root ){

    let date_actu = new Date(root.list_date)

    let Amin = (new Date( date_actu.getFullYear(), 0, 1, 0, 0, 0 )).getTime()
    let Amax = (new Date( date_actu.getFullYear()+1, 0, 1, 0, 0, 0 )).getTime()

    let Mmin = (new Date( date_actu.getFullYear(), date_actu.getMonth(), 1, 0, 0, 0 )).getTime()
    let Mmax = (new Date( date_actu.getFullYear(), date_actu.getMonth()+1, 1, 0, 0, 0)).getTime()

    //reset box state
    root.vente_ca_annuel = 0
    root.vente_ca_mensuel = 0
    root.vente_seance = 0
    root.vente_accesoire = 0

    for( var i in list_fac )
    {
        //get facture
        let buffer = list_fac[i]
        let total = (buffer.pay_cb + buffer.pay_money + buffer.pay_check + buffer.pay_dif + buffer.pay_prele)

        let facd  = (new Date(buffer.createAt)).getTime()

        //Calcule CA anné
        if(facd>=Amin)
        if(facd<=Amax)
            root.vente_ca_annuel += total

        //Calcule CA mois
        if(facd>=Mmin)
        if(facd<=Mmax)
        {
            //CA mois
            root.vente_ca_mensuel += total
        }
    }

    //Item
    Item.getAllMemory( function(items)
    {
        for( var p in items)
        {
            let buffer = items[p]
            let itdate  = (new Date(buffer.createAt)).getTime()

            //Calcule CA mois
            if(itdate>=Mmin)
            if(itdate<=Mmax)
            if(buffer.prix!=0)
            if(buffer.tva!=0)
            if(buffer.type!='re')
            {
                let total = 0
                //Si Facture
                if( buffer.type =='fa' || buffer.type =='re' )
                    total = parseFloat((buffer.prix * ((buffer.tva/100)+1)).toFixed(2))

                //Sinon avoir
                if( buffer.type =='av' )
                    total = parseFloat((buffer.prix * (( (buffer.tva*-1)/100)+1)).toFixed(2))

                if( buffer.credit!=0)
                    root.vente_seance += total
                else
                    root.vente_accesoire += total



            }


        }
    })
}

</script>

<style scoped>
.espace{
    padding-left: 5%;
}
</style>
